var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "form"
  }, [_c('attachments', {
    attrs: {
      "editMode": "edit",
      "allowTemplates": false,
      "type": "documents",
      "attachments": _vm.attachments,
      "protected": false,
      "singleFileMode": !_vm.isEditable ? true : false,
      "readOnlyMode": !_vm.isEditable
    },
    on: {
      "change": _vm.refreshAttachments
    },
    scopedSlots: _vm._u([{
      key: "heading",
      fn: function fn() {
        return [_c('sygni-container-title', [_vm._v("Documents")]), _c('sygni-input', {
          attrs: {
            "label": "SECTION NAME",
            "disabled": !_vm.isEditable
          },
          on: {
            "blur": _vm.setCampaignData
          },
          model: {
            value: _vm.sectionNames[_vm.selectedLanguage].documents,
            callback: function callback($$v) {
              _vm.$set(_vm.sectionNames[_vm.selectedLanguage], "documents", $$v);
            },
            expression: "sectionNames[selectedLanguage].documents"
          }
        })];
      },
      proxy: true
    }])
  }), _c('div', {
    staticClass: "form__actions"
  }, [_c('div', {
    staticClass: "form__actions-btns"
  }, [_c('sygni-rect-button', {
    staticClass: "filled red",
    on: {
      "click": function click($event) {
        return _vm.$router.push({
          path: _vm.hasId ? "/".concat(_vm.parentLink, "/capital-rise/campaign/").concat(_vm.$route.params.id, "/form/step-four") : "/".concat(_vm.parentLink, "/capital-rise/new-campaign/form/step-four")
        });
      }
    }
  }, [_vm._v("Next")])], 1), _c('sygni-link-button', {
    attrs: {
      "type": "simple"
    },
    on: {
      "click": function click($event) {
        return _vm.$router.push({
          path: _vm.hasId ? "/".concat(_vm.parentLink, "/capital-rise/campaign/").concat(_vm.$route.params.id, "/form/step-two") : "/".concat(_vm.parentLink, "/capital-rise/new-campaign/form/step-two")
        });
      }
    }
  }, [_vm._v("Back")])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }